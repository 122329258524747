<template>
  <div class="container relofPosses">
    <div>
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">业务单号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="saleOrderId" placeholder="请输入"
              @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">
              仓位：
            </span>
            <el-select class="select_single w200 mr10" v-model="inventory" @change="virtualInventoryChange" filterable
              placeholder="请选择">
              <el-option key="" label="全部" value=""></el-option>
              <el-option v-for="item in inventoryList" :key="item.index" :label="item.name" :value="item.index">
              </el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">库位：</span>
            <el-select class="select_single w200 mr10" v-model="subInventory" filterable placeholder="请选择">
              <el-option key="" label="全部" value=""></el-option>
              <el-option v-for="item in subInventoryList" :key="item.subInventory" :label="item.subInventoryName"
                :value="item.subInventory">
              </el-option>
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="materialCode" placeholder="模糊输入"
              @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">预留ID：</span>
            <el-input class="input_single w200 mr10" v-model.trim="batchNo" placeholder="请输入"
              @keyup.enter.native="searchHandle"></el-input>
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <el-button type="primary" class="mr10" @click="excelDownload" v-preventReClick>导出Excel</el-button>
          <el-button type="primary" class="mr10" @click="relHandle" v-preventReClick>批量解除占货</el-button>
          <el-button size="small" type="primary" icon="el-icon-search" circle title="搜索" @click="searchHandle">
          </el-button>
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs class="subnav_wrap" v-loading="tableLoading" style="min-height: 536px;">
          <singleTable :tableList='relofPossesManageList' :tableLeaderFieldsList='leaderFieldsList'
            :tableTailFieldsList="tailFieldsList" :tableFieldsList="fieldsList" :ifCheckbox="ifCheckbox"
            @checkBoxHandle='checkBoxHandle' @releaseHandle='releaseHandle'></singleTable>
        </el-tabs>
        <div class="pagination_wrap fr">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="currentPage" :page-sizes="[10,50]" :page-size="pageSize"
            layout="sizes, total, prev, pager, next" :total="this.total">
          </el-pagination>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import { constDictList, subInventoryTypeList } from "@/api/publicApi.js";
  import singleTable from "@/layouts/components/common/SingleTable.vue";
  import {
    relofPossesManageListSearch,//列表主数据方法
    closerelgoods//占货解除 支持 [id1,id2]
  } from "@/api/module/relofPossesManageApi.js";
  import { timeFormat } from '@/utils/index.js';
  export default {
    name: "relofPosses",
    props: [],
    data() {
      return {
        pageSize: 10, //每页显示条数
        currentPage: 1, //页数
        total: 0,
        ifCheckbox: true,
        idItems: [],
        checkData:[],
        inventory: '',//职业装仓
        subInventory: '',
        materialCode: '',
        batchNo: '',
        saleOrderId: '',
        factoryBatchNumber: '',
        tableLoading: false,
        inventoryList: [],
        subInventoryList: [],
        relofPossesManageList: [],
        leaderFieldsList: [
        ],
        tailFieldsList: [
          {
            name: "操作",
            field: "",
            class: "",
            minWidth: '10%',
            width: "150",
            isAction: true,
            hasRelease: true,
          }
        ],
        fieldsList: [
          {
            name: '序号',
            field: 'idIndex',
            minWidth: '80'
          },
         
          {
            name: '业务单号',
            field: 'saleOrderId',
            class: 'w9p',
            minWidth: 300,
          },
          // {
          //   name: '来源单据编码',
          //   field: 'reserveId',
          //   minWidth: 300,
          //   class: 'w9p'
          // },
          {
            name: '仓位',
            field: 'inventory',
            class: 'w9p',
            minWidth: 150,
          },
          {
            name: '库位',
            field: 'subInventory',
            minWidth: 150,
            class: 'w9p'
          },
          {
            name: '物料编号',
            field: 'materialCode',
            minWidth: 150,
            class: 'w9p'
          },
          {
            name: '预留ID',
            field: 'batchNo',
            minWidth: 150,
            class: 'w9p'
          },
          {
            name: '预留数量',
            field: 'reserveQuantity',
            minWidth: 150,
            class: 'w9p'
          },
          {
            name: '创建时间',
            field: 'createTime',
            minWidth: '200',
            class: 'w9p'
          },
          {
            name: '更新时间',
            field: 'updateTime',
            minWidth: '200',
            class: 'w9p'
          },
        ],
        tableLoading: false
      };
    },
    components: {
      singleTable
    },
    beforeCreate() {

    },
    created() {

    },
    mounted() {
      this.getInventoryList();
      // this.virtualInventoryChange('C0');
      this.getRelofPossesManageList();
    },
    activated() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
      excelDownload() {
        //导出Excel
        // console.log('--this.checkData--',this.checkData)
        if(this.checkData.length>0){
          let headerList = [];
          let fieldsList = ['idIndex', 'saleOrderId', 'inventory', 'subInventory','materialCode','batchNo','reserveQuantity','createTime','updateTime'];
          this.fieldsList.forEach(hItem => {
            headerList.push(hItem.name);
          });
          import('@/vendor/Export2Excel').then(excel => {
            // const list = this.relofPossesManageList;
            const list = this.checkData;
            const data = this.formatJson(fieldsList, list);
            excel.export_json_to_excel({
              header: headerList,
              data,
              filename: '占货解除', 
              autoWidth: true, 
              bookType: 'xlsx'
            });
          });
        }else{
          this.$message({
            type: 'error',
            message: '请选择勾选项!'
          });
        }
        
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
      },
      //多选
      checkBoxHandle(val) {
        console.log('---val---',val)
        this.checkData=val;
        let idList = [];
        this.idItems = [];
        if (val.length > 0) {
          for (var i = 0; i < val.length; i++) {
            let item = val[i];
            console.log(item)
            idList.push(item.id)
          }
          this.idItems = idList;
        }
        console.log(idList)
      },
      async getInventoryList() {
        // 仓位
        // constDictList('VirtualInventory').then(res => {
        //   // console.log('仓位列表数据：', res);
        //   if (res.code === '0') {
        //     this.inventoryList = res.data;
        //   }
        // });
        let res = await constDictList('VirtualInventory')
        this.inventoryList = res.data;
      },
      async virtualInventoryChange(val) {
        // 仓位更改
        console.log('仓位更改：', val);
        await subInventoryTypeList(val).then(res => {
          console.log('库位列表：', res);
          let outList = res.data;  // 库位列表数据
          this.subInventoryList = outList;
          this.$forceUpdate();

        });
      },
      //获取状态名称
      typeHandle(status, list) {
        for (var i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.index == status) {
            return item.name
          }
        }
      },
      searchHandle() {
        // 搜索
        this.currentPage=1 
        this.getRelofPossesManageList();
      },
      getRelofPossesManageList() {
        this.tableLoading = true;
        let data = {
          inventory: this.inventory,//=="职业装仓" ? 'C0' : this.inventory,//仓位
          subInventory: this.subInventory,//库位
          materialCode: this.materialCode,//物料编号
          batchNo: this.batchNo,//批次号
          saleOrderId: this.saleOrderId,//单据号字段待定

        };
        relofPossesManageListSearch(this.currentPage, this.pageSize, data).then(res => {
          console.log('预留主数据列表：', res);
          if (res.code === '0') {
            this.tableLoading = false;
            this.relofPossesManageList = res.data.records;
            this.total = res.data.total;
            for (var i = 0; i < this.relofPossesManageList.length; i++) {
              let item = this.relofPossesManageList[i];
              if (this.currentPage != 1) {
                item.idIndex = (this.currentPage - 1) * this.pageSize + (i + 1);
              } else {
                item.idIndex = i + 1;
              }
              item.createTime = item.createTime ? timeFormat(item.createTime) : item.createTime;
              item.updateTime = item.updateTime ? timeFormat(item.updateTime) : item.updateTime;
            }
            this.$forceUpdate();
          } else {
            this.tableLoading = false;
          }
        });
      },
      //批量解除战火
      relHandle() {
        
        if (this.idItems.length > 0) {
          this.resGoodslick(this.idItems)
        } else {
          this.$message({
            type: 'error',
            message: '请选择勾选项!'
          });
        }
      },
      // 单项占货解除
      releaseHandle(index, item) {
        console.log(index, item);
        let idarr=[]
        idarr.push(item.id)
        this.resGoodslick(idarr)
      },


    resGoodslick(item){
     //占货解除
     closerelgoods(item).then(res => {
       debugger
        if (res.code == '0') {
          this.$message({
            type: 'success',
            message: '操作成功！'
          });
          this.getRelofPossesManageList();
        }
      });
    },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getRelofPossesManageList();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getRelofPossesManageList();
      },

    }
  };
</script>

<style lang="scss" scoped>
  @import "../../../styles/index.scss";

  * {
    box-sizing: border-box;
  }

  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .red {
    color: #F56C6C;
  }

  .ml {
    margin-left: 10px;
  }

  .container {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
  }

  .fabricMaterials {
    padding: 10px 15px;

    .actions_part {
      margin: 10px 0;
    }
  }

  .table_part {
    position: relative;

    ul {
      li {
        outline: none;
      }

      &.childcontentlist {
        height: 60px;
        line-height: 40px;
      }

      &.childlist {
        li {
          cursor: pointer;
        }
      }
    }

    .submit_btn {
      position: absolute;
      top: -60px;
      right: 0;
    }
  }

  .edit_parent_list {
    ul {
      &.childcontentlist {
        height: 120px;
      }
    }

    .edit_btn_wrap {
      margin-top: 20px;
    }
  }

  .pagination_wrap {
    margin-top: 20px;
  }

  .check_popup_part {
    ul {
      &.childcontentlist {
        height: 80px;
      }
    }
  }

  ul {
    &.childcontentlist {
      li {
        /*white-space: normal;*/
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  i.el-icon-view {
    font-size: 20px;
    color: #409eff;
    margin-left: 10px;
    cursor: pointer;
  }

  .form_part {
    .title {
      margin-bottom: 30px;
      padding: 0 20px;
      font-size: 17px;
      color: #606266;
    }

    .demo-ruleForm {
      width: 45%;
    }
  }

  select.selectstatus {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #DCDFE6;
    text-indent: 1em;
    color: #606266;
    line-height: 20px;
    font-size: 14px;
  }

  select.selectstatus>option {
    font-size: 14px;
  }

  select.selectstatus {
    background: #FFFFFF;
  }

  .el-select.selectfabc {
    margin-right: 10px;
  }
</style>
<style>
  .fabricMaterials label.el-checkbox {
    width: 200px;
  }

  .subnav_wrap .el-tabs__item {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 14px !important;
    vertical-align: baseline !important;
  }

  .subnav_wrap.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 0;
  }

  .subnav_wrap .el-tabs__content {
    overflow: visible;
  }

  .subnav_wrap .checkbox_list .el-checkbox__label {
    width: 100%;
    padding-left: 0;
    color: #606266;
  }

  .subnav_wrap .checkbox_list .el-checkbox {
    width: 100%;
  }

  .subnav_wrap .checkbox_list .el-checkbox__input {
    margin-right: 10px;
  }

  .subnav_wrap ul.el-dropdown-menu li.el-dropdown-menu__item {
    padding: 0 10px;
  }

  .fabricMaterials .el-form-item__label {
    font-size: 14px;
    color: #606266;
    font-weight: normal;
  }

  .fabricMaterials .el-form-item__content {
    width: 60% !important;
  }

  .fabricMaterials .el-input {
    width: auto;
  }

  .fabricMaterials .whole_long .el-form-item__content {
    width: 65%;
  }

  .fabricMaterials .whole_long .el-input {
    width: 100%;
  }

  form.el-form.demo-ruleForm.fl.tag {
    width: 80%;

    .el-form-item {
      width: 63% !important;
    }
  }

  .form_part .demo-ruleForm {
    width: 80%;
  }
</style>