import http from "../http";
import axios from "axios";

/**
 * 查询——占货列表
 * 
 */
export const relofPossesManageListSearch = (currentPage, pageSize,data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `relof/v1/reservematerialdetails/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 单条解除占货
 */
export const closerelgoods = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `relof/v1/omsReleaseReserveById`,
    data
  });
};