<template>
  <div class="materials nav_tab_wrap">
    <el-tabs class="nav_wrap" type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="占货解除" name="getRelofPossesManage">
        <relofPossesManage v-if="isFabric"></relofPossesManage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import relofPossesManage from "./relofPossesManage/Index";
export default {
  name: "relofPossesManages",
  data() {
    return {
      activeName: 'getRelofPossesManage',
      isFabric: true
    };
  },
  components: {
    relofPossesManage
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleClick(tab) {
      switch (tab.name) {
        case 'getRelofPossesManage':
          this.isFabric = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>