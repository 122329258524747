var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container relofPosses" }, [
    _c("div", [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("业务单号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.saleOrderId,
                  callback: function($$v) {
                    _vm.saleOrderId = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "saleOrderId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [
                _vm._v("\n            仓位：\n          ")
              ]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.virtualInventoryChange },
                  model: {
                    value: _vm.inventory,
                    callback: function($$v) {
                      _vm.inventory = $$v
                    },
                    expression: "inventory"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.inventoryList, function(item) {
                    return _c("el-option", {
                      key: item.index,
                      attrs: { label: item.name, value: item.index }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("库位：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.subInventory,
                    callback: function($$v) {
                      _vm.subInventory = $$v
                    },
                    expression: "subInventory"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.subInventoryList, function(item) {
                    return _c("el-option", {
                      key: item.subInventory,
                      attrs: {
                        label: item.subInventoryName,
                        value: item.subInventory
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "模糊输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("预留ID：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.batchNo,
                  callback: function($$v) {
                    _vm.batchNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "batchNo"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" }
                ],
                staticClass: "mr10",
                attrs: { type: "primary" },
                on: { click: _vm.excelDownload }
              },
              [_vm._v("导出Excel")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" }
                ],
                staticClass: "mr10",
                attrs: { type: "primary" },
                on: { click: _vm.relHandle }
              },
              [_vm._v("批量解除占货")]
            ),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table_part clearfix" },
        [
          _c(
            "el-tabs",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "subnav_wrap",
              staticStyle: { "min-height": "536px" }
            },
            [
              _c("singleTable", {
                attrs: {
                  tableList: _vm.relofPossesManageList,
                  tableLeaderFieldsList: _vm.leaderFieldsList,
                  tableTailFieldsList: _vm.tailFieldsList,
                  tableFieldsList: _vm.fieldsList,
                  ifCheckbox: _vm.ifCheckbox
                },
                on: {
                  checkBoxHandle: _vm.checkBoxHandle,
                  releaseHandle: _vm.releaseHandle
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination_wrap fr" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 50],
                  "page-size": _vm.pageSize,
                  layout: "sizes, total, prev, pager, next",
                  total: this.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }